import React from "react"
import { Script, ScriptStrategy, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { BgImage } from "gbimage-bridge"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SgHeadings from "../components/sg-headings"
import Reactmarkdown from "react-markdown"
import Peers from "../components/peers"



export default function SignUpPage ({ data })
{
  const seo = {
    metaTitle: data.strapiSignUp.headline,
    metaDescription: data.strapiSignUp.shortDesc,
  }

  // background image with headline, custom text and a custom search on the main page
 const sqry = data.strapiSignUp
 const background = getImage(sqry.backgroundImage.localFile)
 const headline = sqry.headline
 const shortDesc = sqry.shortDesc
 const heroImage = getImage(sqry.heroImage.localFile)
 const howItWorks = sqry.howItWorks.data.howItWorks
 const alternativeText = "How it works for Suppliers - Toristy" 
 const testimonials = data.allStrapiTestimonial.nodes

  return (
    <Layout>
      <Seo seo={seo} />
      <div className="relative h-96 w-full h-full bg-gradient-to-r from-purple-900 to-cyan-900">
        <BgImage 
        image={background} 
        alt={alternativeText} 
        className="absolute h-96 bg-center object-cover mix-blend-overlay" />
        <div className="container absolute top-0 left-1/2 -translate-x-1/2">
          <SgHeadings title={headline} description={shortDesc} /></div>
      </div>
      <div className="container pt-6 m-auto px-6 text-gray-600 md:px-12 xl:px-6">
          <div className="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
            <div className="md:5/12 lg:w-5/12"><GatsbyImage image={heroImage} alt="Toristy Integrations" /></div>
                <div className="md:7/12 lg:w-6/12">
                  <h2 className="p-1 text-2xl text-cyan-900 md:text-4xl">How It Works</h2>
                  <div className="p-2 space-y-4"><Reactmarkdown>{howItWorks}</Reactmarkdown></div>
                <div className="text-right">
                <button id="cta" type="submit" className="bg-brandblue text-center text-white py-2 px-4 rounded-lg focus:ring focus:ring-blue-300 hover:bg-brandgreen">
                      <Link to="#form">Find out more</Link>
                </button>         
                </div>
            </div>
          </div>
      </div>
      <Peers />
      <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
      {testimonials.map(testimonial => (
      <figure key={testimonial.id} className="container mt-12 bg-slate-100 shadow-md rounded-2xl pt-2 relative">
            <GatsbyImage className="w-24 h-24 md:w-36 md:h-auto rounded-full float-right" 
            image={testimonial.headshot.localFile.childImageSharp.gatsbyImageData} 
            alt="Testimonial Toristy Travel" 
            />
            <div className="pt-6 md:p-6 space-y-4">
                <blockquote>
                <div className="text-base font-normal">
                   <Reactmarkdown>{testimonial.body.data.body}</Reactmarkdown>
                 </div>
                </blockquote>
                <figcaption className="font-medium">
                <div className="text-slate-700">
                    {testimonial.name}
                </div>
                <div className="text-sky-500">
                    <a href={testimonial.url}>{testimonial.title}</a>
                </div>
                </figcaption>
            </div>
        </figure>
      ))}
      </div>
      <div className="container grid grid-cols-1 md:grid-cols-2 gap-4 pb-4">
      <div className="pt-6 md:p-6 space-y-4" id="form">
        <h2 className="text-2xl font-normal">Onboarding Request Form</h2>
        <p>Apply here to join our platform and learn more about selling your services with hotels, travel agencies, travel websites,
        ferries, airlines or rail companies in your region.
        In order to process your request we need you to give us a little more information to make our process as fast as possible.
        Filling in this information means you agree to our standard <Link to="/legal/terms" className="text-cyan-700 hover:text-emerald-500">terms of service</Link>. Please fill in the form as best you can. </p>
      </div>
      <div className="p-8 bg-slate-100 shadow-md rounded-2xl">
      <span className="text-xs">* Required field</span>
      <Script src="https://www.google.com/recaptcha/api.js" strategy={ScriptStrategy.offMainThread} defer async></Script>
      <form method="post" action="https://usebasin.com/f/8f5ca06fabf0">
        <input type="hidden" name="source" value="Toristy.com Supplier request"></input>
        <div className="space-x-4 pb-2">
        <label htmlFor="firstName" className="text-cyan-700">First Name* <input className="rounded-lg w-full" type="text" name="firstName" /></label>
        </div>
        <div className="space-x-4 pb-2">
        <label htmlFor="lastName" className="text-cyan-700">Last Name* <input className="rounded-lg w-full" type="text" name="lastName" /></label>
        </div>
        <div className="col-span-1 pb-2">
        <label htmlFor="email" className="text-cyan-700">Email* <input className="rounded-lg w-full" type="email" name="email" />
        </label>
        </div>
        <div className="space-x-4 pb-2">
        <label htmlFor="businessName" className="text-cyan-700">
        Business Name* <input className="rounded-lg w-full" type="text" name="businessName" /></label>
        </div>
        <div className="space-x-4 pb-2">
        <label htmlFor="vatNumber" className="text-cyan-700">
        VAT or Business ID <input className="rounded-lg w-full" type="text" name="vatNumber" /></label>
        </div>
        <div className="space-x-4 pb-2">
        <input type="checkbox" id="fifteenPercent" name="fifteenPercent" defaultChecked />
        <label htmlFor="fifteenPercent">*I agree to 15% commission under the  <Link to="/legal/terms" className="text-cyan-700 hover:text-emerald-500">terms of service</Link>
        </label>
        </div>
        <div className="space-x-4 pb-2">
        <label htmlFor="websiteAddress" className="text-cyan-700">
        Website address <input className="rounded-lg w-full" type="text" name="websiteAddress" />
        </label>
        </div>
        <div className="space-x-4 pb-2">
        <label htmlFor="annualBookings" className="text-cyan-700">My estimated annual bookings are <input className="rounded-lg w-full" type="text" name="annualBookings" />
        </label>
        </div>
        <span className="text-cyan-700">Please tick all that apply</span>
        <ul className="list-none pb-2">
        <li><input type="hidden" name="bokun" value="no" /><input type="checkbox" name="bokun" id="bokun" key="bokun" value="yes" /><label htmlFor="bokun"> My Business uses Bókun </label></li>
        <li><input type="hidden" name="fareharbor" value="no" /><input type="checkbox" name="fareharbor" id="fareharbor" key="fareharbor"  value="yes" /><label htmlFor="fareharbor"> My Business uses Fareharbor</label></li>
        <li><input type="hidden" name="johku" value="no" /><input type="checkbox" name="johku" key="johku" value="yes" /><label htmlFor="johku"> My Business uses Johku</label></li>
        <li><input type="hidden" name="differentTool" value="no" /><input type="checkbox" name="differentTool" key="differentTool" value="yes" /><label htmlFor="differentTool"> My Business uses a different tool</label></li>
        <li><input type="hidden" name="noTool" value="no" /><input type="checkbox" name="noTool" key="noTool" value="yes" /><label htmlFor="noTool"> I don't use any booking tools</label></li>
        <li><input type="hidden" name="noWebsite" value="no" /><input type="checkbox" name="noWebsite" key="noWebsite" value="yes" /><label htmlFor="noWebsite"> I don't have a website</label></li>
       
        </ul>
        <div className="g-recaptcha pb-2" data-sitekey="6Lew3SMUAAAAAJ82QoS7gqOTkRI_dhYrFy1f7Sqy"></div>
        <button className="bg-brandblue text-center text-white py-2 px-4 rounded-lg focus:ring focus:ring-blue-300 hover:bg-brandgreen" 
        type="submit">Send application</button>
      </form>
      </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query{
    strapiSignUp {
    headline
    shortDesc
    heroImage {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    howItWorks {
      data {
        howItWorks
      }
    }
    backgroundImage {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
  allStrapiTestimonial {
    nodes {
      headshot {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      name
      body {
        data {
          body
        }
      }
      title
      url
      id
    }
  }
  }
  `

  